import i18n from "i18next";
import Globals from './data/global';
import { reactI18nextModule } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import { encryptStorage } from './data/session';
import en from './locales/en/translation.json';
import { GiConsoleController } from "react-icons/gi";

const getLocale = () => {
  return encryptStorage.getItem('DEFAULT_LOCALE_CODE');
}

const getCompany = () => {
  return encryptStorage.getItem('COMPANY_ID');
}

const getTheme = () => {
  return encryptStorage.getItem('theme_id');
}

const getInterval = () =>{
  var DEFAULT_LOCALE = getLocale();
  return (typeof DEFAULT_LOCALE !== 'undefined' ) ? false : 5000
}

const loadLocaleBundle = (lang) => {
  var DEFAULT_LOCALE = getLocale();
  var company_id = getCompany(); 
  var theme_id = getTheme();
  if(typeof DEFAULT_LOCALE !== 'undefined' ) { 
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',      
    };    
    
    return Promise.resolve(
      fetch(Globals.API.BASE_URL+company_id+'/theme/'+theme_id+'/locale/'+lang, requestOptions)
        .then(response => response.json())
        .then(result => {          
          // console.log("locale",result);
            return JSON.parse(result.data);
        })
        .catch(error => console.log('error', error))
    );
  } 
  // return Promise.resolve(en);
};

const backendOptions = {
  loadPath: getLocale(), 
  // reloadInterval:getInterval(),
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split('|');
      loadLocaleBundle(getLocale()).then((data) => {
        callback(null, {
          data: JSON.stringify(data),
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en', // use en if detected lng is not available
    debug: false, 
    // lng: localStorage.getItem(DEFAULT_LOCALE) || 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: backendOptions
  });

export default i18n;
