import React,{lazy} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { encryptStorage } from '../data/session';

const SignInView = lazy(() => import("../theme2/views/account/SignIn"));
const SignUpView = lazy(() => import("../theme2/views/account/SignUp"));
const SignOutView = lazy(() => import("../theme2/views/account/SignOut"));
const SessionOutView = lazy(() => import("../theme2/views/account/SessionOut"));
const ForgotPasswordView = lazy(() => import("../theme2/views/account/ForgotPassword"));
const OrdersView = lazy(() => import("../theme2/views/account/Orders"));
const OrderDetailView = lazy(() => import("../theme2/views/account/OrderDetail"));
const WishlistView = lazy(() => import("../theme2/views/account/Wishlist"));
const NotificationView = lazy(() => import("../theme2/views/account/Notification"));
const MyProfileView = lazy(() => import("../theme2/views/account/MyProfile"));
// const ProductListView = lazy(() => import("../theme3/views/product/List"));
// const CategoryListView = lazy(() => import("../theme3/views/product/CategoryList"));
// const SearchListView = lazy(() => import("../theme3/views/product/SearchList"));
// const ProductDetailView = lazy(() => import("../theme3/views/product/Detail"));
// const StarZoneView = lazy(() => import("../theme3/views/product/StarZone"));
// const DocumentationView = lazy(() => import("../theme3/views/Documentation"));
// const SupportView = lazy(() => import("../theme3/views/pages/Support"));
// const BlogView = lazy(() => import("../theme3/views/blog/Blog"));
// const BlogDetailView = lazy(() => import("../theme3/views/blog/Detail"));
const NotFoundView = lazy(() => import("../theme2/views/pages/404")); 
const CartView = lazy(() => import("../theme2/views/cart/Cart"));
const CheckoutView = lazy(() => import("../theme2/views/cart/Checkout"));
const CheckoutViewMobile = lazy(() => import("../theme2/views/cart/CheckoutMobile"));
const PaymentStatusView = lazy(() => import("../theme2/views/pages/PaymentStatus"));


const Theme2Route = () => {
  let theme_id = encryptStorage.getItem("theme_id");
    
  const HomeView = lazy(() => import("../theme"+theme_id+"/views/Home"));
  const ContactUsView = lazy(() => import("../theme"+theme_id+"/views/pages/ContactUs"));

  const CategoryView = lazy(() => import("../theme"+theme_id+"/views/Category")); 
  const MenuView = lazy(() => import("../theme"+theme_id+"/views/pages/MenuView")); 
  const CategoryMenuView = lazy(() => import("../theme"+theme_id+"/views/pages/CategoryMenuView")); 
  const CouponView = lazy(() => import("../theme"+theme_id+"/views/pages/CouponView"));

  // const AboutUsView = lazy(() => import("../theme"+theme_id+"/views/pages/AboutUs")); 
  // const ProjectView = lazy(() => import("../theme5/views/pages/Projects"));
  
  return (
    <Switch>
        <Route exact path="/" component={HomeView} />     
        <Route exact path="/categories" component={CategoryView} />
        <Route exact path="/products" component={MenuView}/>  
        <Route exact path="/category/:categoryID" component={CategoryMenuView}/>    
        <Route exact path="/contact-us" component={ContactUsView} />
        {/* <Route exact path="/about-us" component={AboutUsView} /> */}
        {/* <Route exact path="/project" component={ProjectView} /> */}
        <Route exact path="/account/signin" component={SignInView} />
        <Route exact path="/account/signup" component={SignUpView} />
        <Route exact path="/account/logout" component={SignOutView} />
        <Route exact path="/account/expiry" component={SessionOutView} />
        <Route
            exact
            path="/account/forgotpassword"
            component={ForgotPasswordView}
        />
        <Route exact path="/account/profile" component={MyProfileView} />
        <Route exact path="/account/orders" component={OrdersView} />
        <Route exact path="/account/order/detail/:id" component={OrderDetailView} />
        <Route exact path="/account/wishlist" component={WishlistView} />
        {/* <Route
            exact
            path="/account/notification"
            component={NotificationView}
        />
        <Route exact path="/products" component={ProductListView} />
        <Route exact path="/category/:id" component={CategoryListView} />            
        <Route exact path="/product" component={SearchListView} />
        <Route exact path="/product/detail/:id" component={ProductDetailView} />
        <Route exact path="/star/zone" component={StarZoneView} />
        <Route exact path="/documentation" component={DocumentationView} />                 
        <Route exact path="/support" component={SupportView} />
        <Route exact path="/blog" component={BlogView} />
        <Route exact path="/blog/detail" component={BlogDetailView} />
        <Route exact path="/500" component={InternalServerErrorView} /> */}
        <Route exact path="/cart" component={CartView} />
        <Route exact path="/checkout" component={CheckoutView} />
        <Route exact path="/coupons" component={CouponView} />
        
        <Route exact path="/checkoutMobile/:orderId/:companyID/:domainName/:accessToken" component={CheckoutViewMobile} />        
        <Route exact path="/paymentStatus/:deviceType/:status" component={PaymentStatusView} />
        <Route component={NotFoundView} />
    </Switch>
  )
}

export default Theme2Route;