import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { loadState, saveState } from "./localStorage";
import './i18n';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import MetaTagsServer from 'react-meta-tags/server';
import {MetaTagsContext} from 'react-meta-tags';

import { Helmet, HelmetProvider } from 'react-helmet-async';
const persistedState = loadState();
const store = createStore(rootReducer, persistedState);

store.subscribe(() => {
  saveState(store);
});

ReactDOM.render(
  <Provider store={store}>
    <ClearBrowserCacheBoundary auto duration={60000}>
      <App />
    </ClearBrowserCacheBoundary>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
