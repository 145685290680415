import React,{lazy} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { encryptStorage } from '../data/session';

const Theme5Route = () => {
  let theme_id = encryptStorage.getItem("theme_id");
    
  const HomeView = lazy(() => import("../theme"+theme_id+"/views/Home"));
  const ContactUsView = lazy(() => import("../theme"+theme_id+"/views/pages/ContactUs"));
  const AboutUsView = lazy(() => import("../theme"+theme_id+"/views/pages/AboutUs")); 
  const ProjectView = lazy(() => import("../theme5/views/pages/Projects"));
  const NotFoundView = lazy(() => import("../theme"+theme_id+"/views/pages/404"));  

  const TermsView = lazy(() => import("../theme"+theme_id+"/views/Terms"));  
  const PrivacyView = lazy(() => import("../theme"+theme_id+"/views/Privacy"));
  const RefundView = lazy(() => import("../theme"+theme_id+"/views/Refund"));
  
  return (
    <Switch>
        <Route exact path="/" component={HomeView} />                
        <Route exact path="/contact-us" component={ContactUsView} />
        {/* <Route exact path="/about-us" component={AboutUsView} />
        <Route exact path="/project" component={ProjectView} />    */}
        {
            (theme_id === 5 || theme_id === 4) && (
            <Route exact path="/about-us" component={AboutUsView} />
            )
        } 
        {
            (theme_id === 5) && (
            <Route exact path="/project" component={ProjectView} />
            )
        }  
        {
            (theme_id === 6) && (
            <Route exact path="/terms-and-conditions" component={TermsView} />
            )
        }
        {
            (theme_id === 6) && (
            <Route exact path="/privacy-policy" component={PrivacyView} />
            )
        }
        {
            (theme_id === 6) && (
            <Route exact path="/refund-and-cancellation-policy" component={RefundView} />
            )
        }        
        <Route component={NotFoundView} />     
    </Switch>
  )
}

export default Theme5Route;
