import React, {useEffect} from "react";
import axios from "axios";
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({forceRefresh:true})

const checkRequests= Wrapped => {
    function CheckRequests(props) {
        var originalFetch = fetch;
        fetch = function() {
            return originalFetch.apply(this, arguments).then(function(data) {
                // someFunctionToDoSomething();
                // console.log('checkRequests',data.status,data.url.includes('api'))
                var inMaintenance = localStorage.getItem('maintenance');
                if(data.url.includes('api')){
                    switch (data.status) {
                        case 503 :
                            if(inMaintenance=='false'){
                                localStorage.setItem("maintenance",true);
                                history.push('/') //we will redirect user into 503 page 
                            }
                            // history.goBack();
                            // window.location.reload();
                            break
                        case 200 :
                            localStorage.setItem("maintenance",false);
                            break
                        default :
                            break
                    }
                }
                return data;
            });
        };
      
        // console.log('===checkRequests===1');
        // useEffect(()=>{
        //     console.log('===checkRequests===12');
        //     axios.interceptors.response.use(function (response) {
        //         console.log('===checkRequests===',response);
        //         // Do something with response data
        //         return response;
        //     }, function (error) {
        //         console.log('===checkRequests===',error);
        //         switch (error.response.status) {
        //             case 503 :
        //                 props.history.push('/503') //we will redirect user into 503 page 
        //                 break
        //             default :
        //                 break
        //         }
        //         // Do something with response error
        //         return Promise.reject(error);
        //     });
        // })

        return (
            <Wrapped {...props} />
        )
    }
    return CheckRequests
}

export default checkRequests