export default {
    API: {
        CONFIG_URL: 'https://api.k360.store/api/v1/ecommerce/get-company-configuration/',
        BASE_URL: 'https://api.k360.store/api/v1/ecommerce/',
        MAINTENANCE_URL: 'https://api.k360.store/api/maintenance',


        // CONFIG_URL: 'http://127.0.0.1:8000/api/v1/ecommerce/get-company-configuration/',
        // BASE_URL: 'http://127.0.0.1:8000/api/v1/ecommerce/',
        // MAINTENANCE_URL: 'http://127.0.0.1:8000/api/maintenance/',

        // CONFIG_URL: 'http://ec2-44-206-53-181.compute-1.amazonaws.com/api/v1/ecommerce/get-company-configuration/',
        // BASE_URL: 'http://ec2-44-206-53-181.compute-1.amazonaws.com/api/v1/ecommerce/',
        // MAINTENANCE_URL: 'http://ec2-44-206-53-181.compute-1.amazonaws.com/api/maintenance/',

        ACCESS_TOKEN: "TkUUV2LDD2Qgs+KDcGBYc7JogcRygaOomnXxDalvWqRfABF29CWUzOf0+r/t1QBLSqICHNP0tPyI8PvzS8fInyo8dVCpq/v0oDgDatv2WnpLijuO3kqkgE5+0hxs6MUkSKYYRg==",
    },
    CUSTOMER_ID: "3",
    COMPANY_ID: "1",
    IMG_BASE_URL: "https://k360-media.nyc3.cdn.digitaloceanspaces.com/",
    ASSETS_BASE_URL:"https://k360-media.nyc3.cdn.digitaloceanspaces.com/themes/",
}; 
