import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CheckRequests from "./HOC/CheckRequests"
import { Spinner } from 'react-bootstrap';
import loadjs from 'loadjs';
import {  loadCss,loadScripts  }  from 'load-resource-dynamically'; 
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { encryptStorage } from './data/session';
import "./App.css";
import  i18n from "i18next";

import Globals from './data/global';
import Assets from './data/assets';

import Theme2Route from './routes/Theme2Route';
import Theme3Route from './routes/Theme3Route';
import Theme5Route from './routes/Theme5Route';
const MaintenanceView = lazy(() => import("./views/maintenance"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  handleExternalJS() {
    const script = document.createElement("script");    
    script.async = true;    
    script.src = "https://code.jquery.com/jquery-3.3.1.min.js";
    document.head.appendChild(script);
  }

  handleBodyClass(theme_id) {
    const bodyClass = "theme" + theme_id ;
    document.body.classList.add(bodyClass);
  }

  handleDomain() {
    var host = window.location.host;
    // var host = "dbfchennai.org"; 
    var company_domain = host.replace(/.k360.store/g, "");     

    if(host === "covidarmy4india.org") {
      var company_domain = "covidarmy4india";
    }
    if(host === "dbfchennai.org") {
      var company_domain = "dbfchennai";
    }
    if(host === "donboscochennai-formation.org") {
      var company_domain = "dbchennaiformation";
    }

    var companyDomain = encryptStorage.getItem("company_domain");
    if(companyDomain!=company_domain) { 
      encryptStorage.setItem("company_domain", company_domain); 
      encryptStorage.removeItem("DEFAULT_LOCALE");
      encryptStorage.removeItem("i18nextLng");
      document.body.classList.remove(encryptStorage.getItem("DEFAULT_LOCALE_DIRECTION"));
      window.location.reload('true');
    } else {  
      encryptStorage.setItem("company_domain", companyDomain);
    }

    if(host === "k360.store" || host === "www.k360.store") { 
      encryptStorage.setItem("theme_id", 0);
      // checking is site under maintenance      
      var requestOptions = {
        method: 'GET',
        // headers: {'Access-Control-Allow-Origin': '*','Allow-Origin': '*','Content-Type': 'application/json'},
        redirect: 'follow'
      };
      
      fetch(Globals.API.MAINTENANCE_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          // console.log('result', result)
        })
        .catch(error => {
          // console.log('error', error)
        });
      // checking is site under maintenance

      let theme_id = encryptStorage.getItem("theme_id");
      this.handleBodyClass(theme_id);
      if(theme_id===0){
        // loadjs(Assets.theme0assets.JS, () => {});
        this.handleCSS(Assets.theme0assets.CSS)
        this.handleJS(Assets.theme0assets.JS);
      }
      // this.setState({
      //   isLoaded: true,
      // }) 
    } else {  
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(Globals.API.CONFIG_URL+company_domain, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.error === false) {
            var vCompany = result.data.company_details;
            encryptStorage.setItem("COMPANY_ID", vCompany.id);             
            encryptStorage.setItem("COMPANY_NAME", vCompany.name);             
            encryptStorage.setItem("COMPANY_DESC", vCompany.description);          
            encryptStorage.setItem("COMPANY_LOGO", vCompany.logo_url);          
            encryptStorage.setItem("COMPANY_EMAIL", vCompany.email);          
            encryptStorage.setItem("COMPANY_PHONE", vCompany.phone);    
            
            var vConfiguratuions = result.data.configurations;
            const DEFAULT_LOCALE = encryptStorage.getItem('DEFAULT_LOCALE');
            if(DEFAULT_LOCALE){            
              encryptStorage.setItem("DEFAULT_LOCALE", DEFAULT_LOCALE);
              document.documentElement.lang = encryptStorage.getItem("DEFAULT_LOCALE_CODE");
              document.documentElement.dir=encryptStorage.getItem("DEFAULT_LOCALE_DIRECTION");
              document.body.classList.add(encryptStorage.getItem("DEFAULT_LOCALE_DIRECTION"));
            }
            vConfiguratuions.map(config => {
              if(config.option_name=='ecommerce_portal_language' && !DEFAULT_LOCALE){
                const locales = config.option_value.split(','); 
                encryptStorage.setItem("DEFAULT_LOCALE", locales[0]);
              }
              encryptStorage.setItem(config.option_name, config.option_value);
            })      
            encryptStorage.setItem("COMPANY_LOCALES", result.data.locales);   
            result.data.locales.forEach(locale => {
              if(locale.id===encryptStorage.getItem("DEFAULT_LOCALE")){
                encryptStorage.setItem('DEFAULT_LOCALE_NAME',locale.name);
                encryptStorage.setItem('DEFAULT_LOCALE_CODE',locale.code);
                encryptStorage.setItem("DEFAULT_LOCALE_DIRECTION",locale.language_direction);
                document.documentElement.lang = locale.code;
                document.documentElement.dir = locale.language_direction;
                document.body.classList.add(locale.language_direction);
                // console.log("loading here",encryptStorage.getItem("DEFAULT_LOCALE_CODE"));
                // i18n.changeLanguage(encryptStorage.getItem("DEFAULT_LOCALE_CODE"));
                // using Promises
                // i18n.reloadResources()  .then(() => {
                //   console.log("loading here all resource");
                // });
              }
            }); 

            let theme_id = encryptStorage.getItem("theme_id");
            this.handleBodyClass(theme_id);
            if(theme_id===7){
              // loadjs(Assets.theme7assets.JS, () => {});              
              this.handleCSS(Assets.theme7assets.CSS)
              this.handleJS(Assets.theme7assets.JS);
            }
            if(theme_id===6){ 
              // loadjs(Assets.theme6assets.JS, () => {});                          
              this.handleCSS(Assets.theme6assets.CSS)  
              this.handleJS(Assets.theme6assets.JS);     
            }
            if(theme_id===5){ 
              // loadjs(Assets.theme5assets.JS, () => {});           
              this.handleCSS(Assets.theme5assets.CSS)
              this.handleJS(Assets.theme5assets.JS);        
            }
            if(theme_id===4){
              // loadjs(Assets.theme4assets.JS, () => {});                    
              this.handleCSS(Assets.theme4assets.CSS)
              this.handleJS(Assets.theme4assets.JS);
            }
            if(theme_id===3){
              // loadjs(Assets.theme3assets.JS, () => {});            
              this.handleCSS(Assets.theme5assets.CSS)
              this.handleJS(Assets.theme3assets.JS);
            }
            if(theme_id===2){  
              // loadjs(Assets.theme2assets.JS, () => {});            
              this.handleCSS(Assets.theme2assets.CSS)
              this.handleJS(Assets.theme2assets.JS);
            }
            // this.setState({
            //   isLoaded: true,
            // })   
          } else {
            // console.log(result.message);          
            this.setState({
              isLoaded: true,
            })   
          }
        })
        .catch(error => console.log('error', error));
    }    
  }
  scriptLoaded() {
    // console.log('loaded');
  }
  
  handleJS(srcs){       
    (async () => {     
      loadScripts(srcs).then(response => {
        this.setState({
          isLoaded: true,
        }) 
      }).catch(error => {
        // console.log("CSS error>>>>",error);
      })
    })()  
    // for(let i=0;i<srcs.length;i++) {
    //   const script = document.createElement("script");
    //   script.src = srcs[i];
    //   script.async = false;
    //   // script.onload = () => this.scriptLoaded();

    //   document.body.appendChild(script);
    // }
  }  

  handleCSS(srcs){    
    (async () => {     
      loadCss(srcs).then(response => {
        this.setState({
          isLoaded: true,
        }) 
        // return;
      }).catch(error => {

      })
    })()
    // for(let i=0;i<srcs.length;i++) {
    //   var head = document.getElementsByTagName('HEAD')[0]; 
    //     var link = document.createElement('link');
    //       // set the attributes for link element 
    //     link.rel = 'stylesheet';       
    //     link.type = 'text/css';      
    //     link.href = srcs[i]; 
    //     head.appendChild(link); 
    // }
  }

  componentDidMount() {    
    this.handleDomain(); 
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCIsImtpZCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCJ9.eyJhdWQiOiJodHRwczovL2QzNjVmby1rb3JkZXYwMS5rb3Jjb21wdGVuei5jb20iLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9mYmUxYTc0MS1mNGE1LTQxMzUtOWZhYi1mZmU2OGU3MWZjMzkvIiwiaWF0IjoxNjM4MzM2MTEwLCJuYmYiOjE2MzgzMzYxMTAsImV4cCI6MTYzODM0MDAxMCwiYWlvIjoiRTJaZ1lCRE4vcElRR0psdUk3TXRaN2s2eCtrRkFBPT0iLCJhcHBpZCI6IjRhYjdkNDlhLTM5NjItNGZlZS05OTNiLTI1NThmYjFjYzRiYyIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0L2ZiZTFhNzQxLWY0YTUtNDEzNS05ZmFiLWZmZTY4ZTcxZmMzOS8iLCJvaWQiOiIwOWU2YWI5Ny03ZWE1LTRlNmQtYjc0MS02NjBlZWJkYWRmMGIiLCJyaCI6IjAuQVEwQVFhZmgtNlgwTlVHZnFfX21qbkg4T1pyVXQwcGlPZTVQbVRzbFdQc2N4THdOQUFBLiIsInN1YiI6IjA5ZTZhYjk3LTdlYTUtNGU2ZC1iNzQxLTY2MGVlYmRhZGYwYiIsInRpZCI6ImZiZTFhNzQxLWY0YTUtNDEzNS05ZmFiLWZmZTY4ZTcxZmMzOSIsInV0aSI6InBRQVhiemZaOUVXOVUxbmczSFR2QVEiLCJ2ZXIiOiIxLjAifQ.nBGzrMaJdZOcHaZreT_UHwl6ehiKeEk7DzBsHkSA97jDzcc2sAr-pAA5HSBH-BvglFjVQ_m4FSD4KI56rhKj3g27Xb9Fkgs_Nb51_iNWacMeWhhWMadb1SDJRtcvvOyBHH7N1BKZeGkbAq2luAKq4s6lQEYP68m6ybRshBf6cRgn9VP2TFdjAUDYj9a0fJ60bFzn2zq6fM-buQNQ-4-9Tk6tyMpbx1J5RHi5SlIKFLS7eYzJg_YmzEJgoHdO-2RTzi6P7OZ-xUvnaltAwKovpgu3xsQT6NpeohvdkULxamnAPgkvoXD0o6w3t11p2ywAcWUr5J5vYpICpc4L1-fRlw');
    // var requestOptions = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // };
    
    // fetch('https://d365fo-kordev01.korcomptenz.com:8081/index.php/api/profileDetails/1/saran.kirubananthan@korcomptenz.com', requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     console.log('result', result)
    //   })
    //   .catch(error => {
    //     console.log('error', error)
    //   });
  }

  render() {
    const { isLoaded } = this.state;
    let theme_id = encryptStorage.getItem("theme_id");
    theme_id = theme_id!=undefined ? theme_id : 0;
    const inMaintenance = localStorage.getItem('maintenance');
    // console.log("theme_id",theme_id,inMaintenance=='true' ? 'M' : 'L');
    if(isLoaded === false) {
      return(
        <div></div>
        // <div className="text-white text-center mt-3">Loading...</div>
        // <div id="preloader">
        //   <Spinner animation="border" role="status" id="loader" size="lg">
        //     <span className="visually-hidden">Loading...</span>
        //   </Spinner>
        // </div>
      );
    } else {
      return (
        <BrowserRouter>
          <React.Fragment>
            <Suspense
              fallback={
                <div className="text-white text-center mt-3">Loading...</div>
              }
            >     
              {(inMaintenance=='true') && (                
              <Switch>         
                <Route exact path="/" component={MaintenanceView} /> 
              </Switch>
              )}
              {(inMaintenance=='false' && theme_id === 2) && (
                <Theme2Route/>
              )} 
              {(inMaintenance=='false' && theme_id === 3) && (
                <Theme3Route/>
              )} 
              
              {(inMaintenance=='false') && (theme_id === 4 || theme_id === 5 || theme_id === 6 || theme_id === 7 || theme_id === 0) && (
                <Theme5Route/>
              )}
            </Suspense>
          </React.Fragment>
        </BrowserRouter>
      );
    }
  }    
}

export default CheckRequests(App);
