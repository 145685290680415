
import Globals from './global';
export default {
    theme0assets:{
        JS:[
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/aos/aos.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/bootstrap/js/bootstrap.bundle.min.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/glightbox/js/glightbox.min.js",
            // Globals.ASSETS_BASE_URL+"Bootstlander/vendor/isotope-layout/isotope.pkgd.min.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/php-email-form/validate.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/purecounter/purecounter.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/swiper/swiper-bundle.min.js",
            Globals.ASSETS_BASE_URL+"Bootstlander/js/main.js",
        ],
        CSS:[
            "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/aos/aos.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/bootstrap/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/bootstrap-icons/bootstrap-icons.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/boxicons/css/boxicons.min.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/glightbox/css/glightbox.min.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/remixicon/remixicon.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/vendor/swiper/swiper-bundle.min.css",
            Globals.ASSETS_BASE_URL+"Bootstlander/css/style.css"
        ]
    },
    theme7assets:{
        JS:[
            Globals.ASSETS_BASE_URL+"MyResume/vendor/aos/aos.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/bootstrap/js/bootstrap.bundle.min.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/glightbox/js/glightbox.min.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/isotope-layout/isotope.pkgd.min.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/php-email-form/validate.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/purecounter/purecounter.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/swiper/swiper-bundle.min.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/typed.js/typed.min.js",
            Globals.ASSETS_BASE_URL+"MyResume/vendor/waypoints/noframework.waypoints.js",
            Globals.ASSETS_BASE_URL+"MyResume/js/main.js",
        ],
        CSS:[
             "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/aos/aos.css",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/bootstrap/css/bootstrap.min.css",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/bootstrap-icons/bootstrap-icons.css",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/boxicons/css/boxicons.min.css",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/glightbox/css/glightbox.min.css",
          Globals.ASSETS_BASE_URL+"MyResume/vendor/swiper/swiper-bundle.min.css",
          Globals.ASSETS_BASE_URL+"MyResume/css/style.css"
        ]
    },
    theme6assets:{
        JS:[            
            "//code.jquery.com/jquery-1.11.3.min.js",  
            Globals.ASSETS_BASE_URL+"Reveal/vendor/aos/aos.js",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/bootstrap/js/bootstrap.bundle.min.js",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/glightbox/js/glightbox.min.js",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/isotope-layout/isotope.pkgd.min.js",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/php-email-form/validate.js",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/swiper/swiper-bundle.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js",
            Globals.ASSETS_BASE_URL+"Reveal/js/main.js",   
        ],
        CSS:[
            "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Raleway:300,400,500,700,800|Montserrat:300,400,700",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/aos/aos.css",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/bootstrap/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/bootstrap-icons/bootstrap-icons.css",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/boxicons/css/boxicons.min.css",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/glightbox/css/glightbox.min.css",
            Globals.ASSETS_BASE_URL+"Reveal/vendor/swiper/swiper-bundle.min.css",
            Globals.ASSETS_BASE_URL+"Reveal/css/style.css",
        ]
    },
    theme5assets:{
        JS:[            
             Globals.ASSETS_BASE_URL+"Cloud/vendor/aos/aos.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/bootstrap/js/bootstrap.bundle.min.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/glightbox/js/glightbox.min.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/isotope-layout/isotope.pkgd.min.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/php-email-form/validate.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/purecounter/purecounter.js",
             Globals.ASSETS_BASE_URL+"Cloud/vendor/swiper/swiper-bundle.min.js",  
             Globals.ASSETS_BASE_URL+"Cloud/js/main.js",               
        ],
        CSS:[
            "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/aos/aos.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/bootstrap/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/bootstrap-icons/bootstrap-icons.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/boxicons/css/boxicons.min.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/glightbox/css/glightbox.min.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/remixicon/remixicon.css",
            Globals.ASSETS_BASE_URL+"Cloud/vendor/swiper/swiper-bundle.min.css",
            Globals.ASSETS_BASE_URL+"Cloud/css/style.css"
        ]
    },
    theme4assets:{
        JS:[ 
            "https://code.jquery.com/jquery-3.3.1.min.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/bootstrap/js/bootstrap.bundle.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/aos/aos.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/php-email-form/validate.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/swiper/swiper-bundle.min.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/purecounter/purecounter.js",
            Globals.ASSETS_BASE_URL+"Covid/vendor/isotope-layout/isotope.pkgd.min.js",            
            Globals.ASSETS_BASE_URL+"Covid/js/main.js",          
        ],
        CSS:[
            "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i",
            Globals.ASSETS_BASE_URL+"Covid/vendor/bootstrap/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"Covid/vendor/bootstrap-icons/bootstrap-icons.css",
            Globals.ASSETS_BASE_URL+"Covid/vendor/aos/aos.css",
            Globals.ASSETS_BASE_URL+"Covid/vendor/remixicon/remixicon.css",
            Globals.ASSETS_BASE_URL+"Covid/vendor/swiper/swiper-bundle.min.css",
            Globals.ASSETS_BASE_URL+"Covid/vendor/glightbox/css/glightbox.min.css",
            Globals.ASSETS_BASE_URL+"Covid/css/style.css",
            Globals.ASSETS_BASE_URL+"Covid/css/main.css"
        ]
    },
    theme3assets:{
        JS:[ 
            "https://code.jquery.com/jquery-3.3.1.min.js",
            Globals.ASSETS_BASE_URL+"Sono/js/bootstrap-tooltip-custom-class.js", 
            "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js", 
            "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js", 
            Globals.ASSETS_BASE_URL+"Sono/js/jquery.meanmenu.min.js", 
            Globals.ASSETS_BASE_URL+"Sono/js/feather.min.js", 
            "https://premiumlayers.com/html/ecom/js/modernizr-2.6.2.min.js", 
            Globals.ASSETS_BASE_URL+"Sono/js/jquery.glasscase.minf195.js", 
            Globals.ASSETS_BASE_URL+"Sono/js/main.js",
        ],
        CSS:[
            "http://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800",
            "http://fonts.googleapis.com/css?family=Bitter",
            "https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.1.0/css/line-awesome.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"Sono/css/bootstrap-tooltip-custom-class.css",
            "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
            Globals.ASSETS_BASE_URL+"Sono/css/meanmenu.css",
            Globals.ASSETS_BASE_URL+"Sono/css/style.css"
        ]
    },
    theme2assets:{
        JS:[ 
            Globals.ASSETS_BASE_URL+"restaurant/js/vendor/modernizr-2.6.2.min.js",
            Globals.ASSETS_BASE_URL+"restaurant/js/vendor/jquery-1.10.2.min.js", 
            // Globals.ASSETS_BASE_URL+"restaurant/js/jquery.nav.js", 
            // Globals.ASSETS_BASE_URL+"restaurant/js/jquery.sticky.js", 
            // Globals.ASSETS_BASE_URL+"restaurant/js/bootstrap.min.js", 
            // Globals.ASSETS_BASE_URL+"restaurant/js/plugins.js", 
            Globals.ASSETS_BASE_URL+"restaurant/js/wow.min.js", 
            Globals.ASSETS_BASE_URL+"restaurant/js/main.js", 
        ],
        CSS:[
            Globals.ASSETS_BASE_URL+"restaurant/css/bootstrap.min.css",
            Globals.ASSETS_BASE_URL+"restaurant/css/font-awesome.min.css",
            Globals.ASSETS_BASE_URL+"restaurant/css/animate.min.css",
            Globals.ASSETS_BASE_URL+"restaurant/css/main.css",
            Globals.ASSETS_BASE_URL+"restaurant/css/responsive.css"
        ]
    }
}
